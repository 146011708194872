<template>
	<section class="date-time-cache-date-container">
		<CRow class="mb-4">
			<CCol md="12">
				<h4 class="search-weight-title">
					Clear all auto reset
				</h4>
				<div class="description typo-body-2 color-black-45">
					Automatic reset clear all the date and time.
				</div>
			</CCol>
		</CRow>

		<CRow class="mb-4">
			<CCol md="10">
				<BaseInputDate
					v-model="startDateValue"
					label="Select date (optional)"
					placeholder="Select date"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2">
				<BaseInputTime
					v-model="$v.startTimeValue.$model"
					label="Select time"
					:is-valid="!$v.startTimeValue.$error"
					:invalid-feedback="errorStartTime"
				/>
			</CCol>
		</CRow>
	</section>
</template>

<script>
import BaseInputDate from '@/components/BaseInputDate.vue';
import BaseInputTime from '@/components/BaseInputTime.vue';

export default {
	name: 'DateTimeCacheDate',
	components: {
		BaseInputDate,
		BaseInputTime,
	},
	props: {
		startDate: {
			type: [Date, String],
			default: null,
		},
		startTime: {
			type: [String, Number],
			default: null,
		},
	},
	validations() {
		return {
			startTimeValue: {
				validTime(time) {
					return time.length === 5 || time.length === 0;
				},
			},
		};
	},
	computed: {
		startDateValue: {
			get() {
				return this.startDate;
			},
			set(value) {
				this.$emit('update:startDate', value);
			},
		},

		startTimeValue: {
			get() {
				return this.startTime;
			},
			set(value) {
				this.$emit('update:startTime', value);
			},
		},

		errorStartTime() {
			if (!this.startTimeValue.validTime) {
				return 'Time not valid';
			}

			return null;
		},
	},
};
</script>

<style lang="scss" scoped>
.date-time-cache-date-container {
	padding-bottom: 42px;
}
</style>
