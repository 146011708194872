<script>
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import DateTimeCacheDate from '@/components/dateTimeCache/DateTimeCacheDate.vue';
import { mapActions, mapState } from 'vuex';

import { convertDateTimeToUTC, datetimeFormat, scrollToTop } from '../../assets/js/helpers';

export default {
	name: 'DateTimeCache',
	components: {
		BaseModalConfirm,
		DateTimeCacheDate,
	},
	data() {
		return {
			DATE_TIME_CACHE_TITLE: {
				widget: 'widget banner in homepage',
				product_family: 'product family',
				category_content: 'Banner in Category ,LOB, PLP',
				page: 'Static page',
				footer: 'Footer',
				sub_header: 'Sub header',
			},
			selectedTag: null,

			// form data
			startDate: null,
			startTime: '00:00',
		};
	},
	computed: {
		...mapState('dateTimeCaches', {
			list: 'tags',
			config: 'config',
		}),

		isLoading() {
			return this.list.isLoading || this.config.isLoading;
		},

		tags() {
			return this.list.data.map((item) => (item.tag));
		},
	},
	async created() {
		await this.getTags();
		await this.getConfig();

		const { autoClearCacheAt } = this.config.data;
		if (autoClearCacheAt) {
			this.startDate = new Date(datetimeFormat(autoClearCacheAt));
			this.startTime = datetimeFormat(autoClearCacheAt, 'HH:mm');
		}
	},
	methods: {
		...mapActions({
			purgeCacheDateTime: 'dateTimeCaches/purgeCacheDateTime',
			getTags: 'dateTimeCaches/getTags',
			getConfig: 'dateTimeCaches/getConfig',
			updateConfig: 'dateTimeCaches/updateConfig',
		}),
		secondsToTime(secs) {
			const hours = Math.floor(secs / (60 * 60));
			const d4m = secs % (60 * 60); // divisor_for_minutes
			const minutes = Math.floor(d4m / 60);
			const d4s = d4m % 60; // divisor_for_seconds
			const seconds = Math.ceil(d4s);

			if (hours) {
				return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} hours`;
			} else if (minutes) {
				return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} minutes`;
			}

			return `${seconds.toString().padStart(2, '0')} seconds`;
		},
		onClickClear(tag) {
			this.selectedTag = tag;
			this.$refs['modal-clear-cache'].open();
		},
		resetCache() {
			this.selectedTag = null;
		},
		async clearCache() {
			if (this.selectedTag === 'all') {
				await this.purgeCacheDateTime(this.tags);
			} else {
				await this.purgeCacheDateTime([this.selectedTag]);
			}
		},
		async onConfirm() {
			const dateRef = this.$refs.date.$v;
			dateRef.$touch();

			if (!dateRef.$invalid) {
				const payload = {
					auto_clear_cache_at: convertDateTimeToUTC(this.startDate, this.startTime),
				};

				try {
					await this.updateConfig(payload);
				} finally {
					scrollToTop();
				}
			}
		},
	},
};
</script>

<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else class="main-wrapper mt-4">
		<!-- clear all -->
		<CRow class="mb-4">
			<CCol md="9">
				<h4 class="search-weight-title">
					Clear date time cache
				</h4>
				<div class="description typo-body-2 color-black-45">
					Manual reset clear the date and time for user.
				</div>
			</CCol>

			<CCol md="3" class="text-right">
				<button
					class="btn btn-secondary"
					@click="onClickClear('all')"
				>
					Clear all
				</button>
			</CCol>
		</CRow>

		<CRow v-for="item in list.data" :key="item.tag" class="mb-4">
			<CCol md="9">
				<p class="typo-body-1 search-weight-title mb-1">
					{{ DATE_TIME_CACHE_TITLE[item.tag] }}
				</p>
				<div class="description typo-body-2 color-black-45">
					<template>
						resets every {{ secondsToTime(item.ttl) }}
					</template>
				</div>
			</CCol>

			<CCol md="3" class="text-right">
				<button
					class="btn btn-secondary"
					@click="onClickClear(item.tag)"
				>
					Clear
				</button>
			</CCol>
		</CRow>

		<div class="divider"></div>

		<!-- clear all auto reset  -->
		<DateTimeCacheDate
			ref="date"
			:start-date.sync="startDate"
			:start-time.sync="startTime"
		/>

		<BaseActionPanelStickyFooter
			content-class="main-wrapper"
			button-confirm-text="save"
			@onConfirm="onConfirm"
		/>

		<BaseModalConfirm
			ref="modal-clear-cache"
			title="Clear search result cache?"
			description="This action will affect search usage. Future searches will be queried through search engine once."
			primary-button-text="Clear"
			@onClose="resetCache"
			@onConfirm="clearCache"
		/>
	</div>
</template>

<style scoped lang="scss">
.divider {
	border-top: 1px solid #ddd;
	margin: 32px 0;
}
</style>
